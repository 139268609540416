let API_SERVER_VAL = '';
let PUBLIC_URL_VAL = '';
let MEDIA_SERVER_VAL = '';
let REACT_APP_REACT_ADMIN_PANEL_URL_VAL = '';
let STORE_ID_VAL = '';
let PDF_MENU_ID_VAL = '';
let APIKEY = "";
let AUTHDOMAIN = "";
let PROJECTID = "";
let STORAGEBUCKET = "";
let MESSAGINGSENDERID = "";
let APPID = "";
let MEASUREMENTID = "";
let STRIPEPUBLISABLEKEY = "";
let STRIPECHECKOUTPRICEREFERENCE = "";
let STRIPECUSTOMERPORTALURL = "";


switch (process.env.NODE_ENV) {
  case 'development':
    // API_SERVER_VAL = 'https://epicaleather.com';
    // MEDIA_SERVER_VAL = 'http://localhost:8000';
    // PUBLIC_URL_VAL = 'http://localhost:3000';
    // REACT_APP_REACT_ADMIN_PANEL_URL_VAL = 'http://localhost:3001';
    API_SERVER_VAL = process.env.REACT_APP_API_SERVER;
    MEDIA_SERVER_VAL = process.env.REACT_APP_MEDIA_SERVER;
    PUBLIC_URL_VAL = process.env.REACT_APP_PUBLIC_URL_VAL;
    REACT_APP_REACT_ADMIN_PANEL_URL_VAL = process.env.REACT_APP_REACT_ADMIN_PANEL_URL_VAL;
    STORE_ID_VAL = process.env.REACT_APP_STORE_ID_VAL;
    PDF_MENU_ID_VAL = process.env.REACT_APP_PDF_MENU_ID_VAL;
    APIKEY = process.env.REACT_APP_API_KEY;
    AUTHDOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
    PROJECTID = process.env.REACT_APP_PROJECT_ID;
    STORAGEBUCKET = process.env.REACT_APP_STORAGE_BUCKET;
    MESSAGINGSENDERID = process.env.REACT_APP_MESSAGING_SENDER_ID;
    APPID = process.env.REACT_APP_APP_ID;
    MEASUREMENTID = process.env.REACT_APP_MEASUREMENT_ID;
    STRIPEPUBLISABLEKEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    STRIPECHECKOUTPRICEREFERENCE = process.env.REACT_APP_STRIPE_CHECKOUT_PRICE_REFERENCE;
    STRIPECUSTOMERPORTALURL = process.env.REACT_APP_CUSTOMER_PORTAL_URL;
    break;
  case 'production':
    API_SERVER_VAL = process.env.REACT_APP_API_SERVER;
    MEDIA_SERVER_VAL = process.env.REACT_APP_MEDIA_SERVER;
    PUBLIC_URL_VAL = process.env.REACT_APP_PUBLIC_URL_VAL;
    REACT_APP_REACT_ADMIN_PANEL_URL_VAL = process.env.REACT_APP_REACT_ADMIN_PANEL_URL_VAL;
    STORE_ID_VAL = process.env.REACT_APP_STORE_ID_VAL;
    PDF_MENU_ID_VAL = process.env.REACT_APP_PDF_MENU_ID_VAL;
    APIKEY = process.env.REACT_APP_API_KEY;
    AUTHDOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
    PROJECTID = process.env.REACT_APP_PROJECT_ID;
    STORAGEBUCKET = process.env.REACT_APP_STORAGE_BUCKET;
    MESSAGINGSENDERID = process.env.REACT_APP_MESSAGING_SENDER_ID;
    APPID = process.env.REACT_APP_APP_ID;
    MEASUREMENTID = process.env.REACT_APP_MEASUREMENT_ID;
    STRIPEPUBLISABLEKEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    STRIPECHECKOUTPRICEREFERENCE = process.env.REACT_APP_STRIPE_CHECKOUT_PRICE_REFERENCE;
    STRIPECUSTOMERPORTALURL = process.env.REACT_APP_CUSTOMER_PORTAL_URL;
    //if env. does not continue with REACT_APP env variables does not work written on the documentation
    break;
  default:
    API_SERVER_VAL = 'http://localhost:8000';
    MEDIA_SERVER_VAL = 'http://localhost:8000';
    PUBLIC_URL_VAL = 'http://localhost:3000';
    REACT_APP_REACT_ADMIN_PANEL_URL_VAL = 'https://app.tabulaintegrations.com';
    STORE_ID_VAL = '1wrbfzsiq';
    PDF_MENU_ID_VAL = '4321';
    APIKEY = process.env.REACT_APP_API_KEY;
    AUTHDOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
    PROJECTID = process.env.REACT_APP_PROJECT_ID;
    STORAGEBUCKET = process.env.REACT_APP_STORAGE_BUCKET;
    MESSAGINGSENDERID = process.env.REACT_APP_MESSAGING_SENDER_ID;
    APPID = process.env.REACT_APP_APP_ID;
    MEASUREMENTID = process.env.REACT_APP_MEASUREMENT_ID;
    STRIPEPUBLISABLEKEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    STRIPECHECKOUTPRICEREFERENCE = process.env.REACT_APP_STRIPE_CHECKOUT_PRICE_REFERENCE;
    STRIPECUSTOMERPORTALURL = process.env.REACT_APP_CUSTOMER_PORTAL_URL;
    break;
}

export const API_SERVER = API_SERVER_VAL;
export const PUBLIC_URL = PUBLIC_URL_VAL;
export const MEDIA_SERVER = MEDIA_SERVER_VAL;

export const REACT_ADMIN_PANEL_URL = REACT_APP_REACT_ADMIN_PANEL_URL_VAL;

export const STORE_ID = STORE_ID_VAL;

export const PDF_MENU_ID = PDF_MENU_ID_VAL;

export const FIREBASE_CONFIG = {
    apiKey: APIKEY,
    authDomain: AUTHDOMAIN,
    projectId: PROJECTID,
    storageBucket: STORAGEBUCKET,
    messagingSenderId: MESSAGINGSENDERID,
    appId: APPID,
    measurementId: MEASUREMENTID
}
export const STRIPE_PUBLISHABLE_KEY = STRIPEPUBLISABLEKEY;
export const STRIPE_CHECKOUT_PRICE_REFERENCE = STRIPECHECKOUTPRICEREFERENCE;

export const STRIPE_CUSTOMER_PORTAL_URL = STRIPECUSTOMERPORTALURL;

export const SESSION_DURATION = 5 * 3600 * 1000;
