export const addOrUpdateQueryParam = (url, param, value) => {
  const urlObj = new URL(url, window.location.origin); // Ensure URL object is created correctly
  const params = new URLSearchParams(urlObj.search);

  params.set(param, value);

  // Update the search property of the URL object
  urlObj.search = params.toString();

  return urlObj.toString();
}
export const getRelativeUrl = (fullUrl) => {
  const urlObj = new URL(fullUrl);
  return `${urlObj.pathname}${urlObj.search}`;
}
