import axios from 'axios';
import {auth} from '../firebase';
import {transformPayloadToSend} from "./transformPayload";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});


const getAuthToken = async () => {
  const localToken = sessionStorage.getItem('AuthToken');
  if (localToken) {
    return localToken;
  }

  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken(); // Get the Firebase auth token
        sessionStorage.setItem('AuthToken', token); // Store the token in local storage


        resolve(token);
      } else {
        resolve(null);
      }
    }, reject);
  });
};


axiosInstance.interceptors.request.use(async function (config) {
  try {
    const token = await getAuthToken(); // Get the auth token, from local storage or Firebase

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Set the token in the Authorization header
    }
    if (config.data) {
      config.data = transformPayloadToSend(config.data);
    }

    return config;
  } catch (error) {
    console.error('Error in Axios interceptor:', error);
    return Promise.reject(error); // Reject the request if there's an error
  }
}, function (error) {
  return Promise.reject(error);
});

export default axiosInstance;

