import axios from "../../helpers/axios";
import * as settings from "../../settings";
import {toast} from "react-toastify";
import {addOrUpdateQueryParam, getRelativeUrl} from "../../helpers/urlHelper";
import {
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,
  AUTH_LOGOUT,
  CREATE_AUTH_USER_START,
  CREATE_AUTH_USER_SUCCESS,
  CREATE_AUTH_USER_FAIL
} from "./firebaseAuthTypes";
import {createUserWithEmailAndPassword, signInWithEmailAndPassword} from 'firebase/auth';
import {auth} from "../../firebase";
import { signOut } from 'firebase/auth';

// Action Creators
export const authStart = () => (
  {type: AUTH_START}
);

export const authSuccess = (userCredential, user, idTokenResult) => {
  storeSessionData(user, userCredential, idTokenResult);
  return {
    type: AUTH_SUCCESS,
    idToken: user.accessToken,
    user,
    userCredential,
    idTokenResult,
    adminDeveloper: idTokenResult.claims?.['admin_developer'] === true,
    premiumUser: idTokenResult.claims?.['stripeRole'] === 'premium',
    trialUser: idTokenResult.claims?.['stripeRole'] === 'trial',
    trialStartAt: idTokenResult.claims?.['trial_start_at'],
    trialEndAt: idTokenResult.claims?.['trial_end_at'],
    isTrialEnded: idTokenResult.claims?.['stripeRole'] === 'trial_ended',
  };
};

export const authFail = (error) => (
  {
    type: AUTH_FAIL,
    error
  }
);

export const authLogout = (navigate, currentPath) => {
  return async (dispatch) => {
    try {
      // Sign out of Firebase
      await signOut(auth);

      // Clear session data
      clearSessionData();
      const returnUrl = window.location;

      // Prepare navigation URL
      let finalUrl;if (currentPath.links){
        finalUrl = getRelativeUrl(addOrUpdateQueryParam(returnUrl, 'links', currentPath.links));
        if (currentPath['logged-in']){
          finalUrl = getRelativeUrl(addOrUpdateQueryParam(finalUrl, 'logged-in', false));
        }
      }
      else if (currentPath['logged-in']){
        finalUrl = getRelativeUrl(addOrUpdateQueryParam(returnUrl, 'logged-in', false));
      }


      // Navigate to the final URL
      navigate(finalUrl);

      // Dispatch logout action
      dispatch({ type: AUTH_LOGOUT });
    } catch (error) {
      console.error('Error signing out:', error);
      // Handle sign-out errors if necessary
    }
  };
};

export const createAuthUserStart = () => (
  {type: CREATE_AUTH_USER_START}
);

export const createAuthUserSuccess = (userCredential, user, idTokenResult) => {
  storeSessionData(user, userCredential, idTokenResult);
  return {
    type: CREATE_AUTH_USER_SUCCESS,
    idToken: user.accessToken,
    user,
    userCredential,
    idTokenResult,
    adminDeveloper: idTokenResult.claims?.['admin_developer'] === true,
    premiumUser: idTokenResult.claims?.['stripeRole'] === 'premium',
    trialUser: idTokenResult.claims?.['stripeRole'] === 'trial',
    trialStartAt: idTokenResult.claims?.['trial_start_at'],
    trialEndAt: idTokenResult.claims?.['trial_end_at'],
    isTrialEnded: idTokenResult.claims?.['stripeRole'] === 'trial_ended',
  };
};

export const createAuthUserFail = (error) => (
  {
    type: CREATE_AUTH_USER_FAIL,
    error
  }
);

// Utility Functions
const storeSessionData = (user, userCredential, idTokenResult) => {
  sessionStorage.setItem('RefreshToken', user.refreshToken);
  sessionStorage.setItem('RefreshToken', userCredential?._tokenResponse?.refreshToken);
  sessionStorage.setItem('AuthToken', user?.accessToken);
  sessionStorage.setItem('idTokenResult', JSON.stringify(idTokenResult));
  sessionStorage.setItem('claims', idTokenResult.claims?.['admin_developer']);
  // TODO: Add expiration date handling
};


const clearSessionData = () => {
  sessionStorage.removeItem('RefreshToken');
  sessionStorage.removeItem('AuthToken');
  sessionStorage.removeItem('idTokenResult');
  sessionStorage.removeItem('claims');
};


export const logOutFromFirebase = (history, currentPath) => async (dispatch) => {
  dispatch(authLogout(history, currentPath));
};

export const createAuthUser = (email, password, history) => async (dispatch) => {
  dispatch(createAuthUserStart());
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    toast.success("Account created successfully");
    const idTokenResult = await user.getIdTokenResult();
    dispatch(createAuthUserSuccess(userCredential, user, idTokenResult));
    history.push("/");
  }
  catch (error) {
    toast.error(error.message);
    dispatch(createAuthUserFail(error));
  }
};
