import {
  LINKS_FETCH_SUCCESS,
  LINKS_FETCH_FAILURE,
  LINKS_UPDATE_SUCCESS,
  LINKS_UPDATE_FAILURE,
} from '../actions/linksActionTypes';

const initialState = {
  id: "J3RX2RQDS",
  links: [
    {
      id: "7",
      image: "https://delo.com",
      linkAddress: "https://www.google.com/search?q=Tabula&stick=H4sIAAAAAAAA_-NgU1I1qLCwMEpLMki2SDM2TDM2skyxMqhItrRINEg0MkkyMbQwNTBNW8TKFpKYVJqTCACyHZLBMgAAAA&hl=en&mat=CfSHl9ACWV9ZElcBEKoLaSvQ_7w0eS6tsG49AlvjoPsGes8uru1DBCZ-cFtpm9hEYQ92Ht0WatoqwUhdmJYAoUbGjrRqt0Hc9YwcJ7UmxFSGY1iB2HKSivSNQBth83Gk4dQ&authuser=0#mpd=promote/photos/mediatool",
      order: 2,
      name: "Google Review",
      description: "link_description",
      plan: "8b1t5blwh",
      clicks: 3,
      socialLinkType: null,
      isActive: true
    },
    {
      id: "6",
      image: "https://imange11.com",
      linkAddress: "https://calendly.com/tabulaintegrations-info/30min",
      order: 1,
      name: "book a meeting",
      description: "",
      plan: "8b1t5blwh",
      clicks: 0,
      socialLinkType: null,
      isActive: true
    },
    {
      id: "8",
      image: "https://imange.com",
      linkAddress: "https://imange.com",
      order: 1,
      name: "Special Offers",
      description: "linkkk",
      plan: "8b1t5blwh",
      clicks: null,
      socialLinkType: null,
      isActive: true
    },
    {
      id: "UBQFQA63R",
      image: "https://delo1111.com",
      linkAddress: "https://delo1111.com",
      order: 1,
      name: "Reservation",
      description: "<string>",
      plan: "8b1t5blwh",
      clicks: null,
      socialLinkType: null,
      isActive: true
    }
  ],
  activeSubscription: false,
  connectedTable: {
    id: "83l75y60s",
    storeId: "6275547av",
    number: 1,
    qrCode: "https:qrcode",
    status: "available"
  },
  directRedirect: true,
  directRedirectLink: null,
  pdfLinks: [
    {
      id: "dzc3cehu0",
      name: "Our Menu",
      pdf: "/media/restaurant/pdf/chp_etkinlik_duyurusu_final.pdf",
      description: "pdf link",
      order: 1,
      clicks: null,
      socialLinkType: null,
      isActive: true

    }
  ],
  plan: "8b1t5blwh",
  socialLinks: [
    {
      id: "BIX8F7UQU",
      image: "https://twitter.com",
      linkAddress: "https://twitter.com",
      order: 1,
      name: "twitter",
      description: "twitter link",
      plan: "8b1t5blwh",
      social_link_type: "twitter",
      clicks: null,
      socialLinkType: "twitter",
      isActive: true
    },
    {
      id: "KZ2T7BFMD",
      image: "https://www.instagram.com",
      linkAddress: "https://www.instagram.com",
      order: 1,
      name: "instagram",
      description: "instagram link",
      plan: "8b1t5blwh",
      social_link_type: "instagram",
      clicks: 3,
      socialLinkType: "instagram",
      isActive: true
    }
  ],
  backgroundImage: "https://tabula-app-bucket.s3.amazonaws.com/static/links/background/images/background.jpg",
  logoImage: "https://tabula-app-bucket.s3.amazonaws.com/static/links/logo/images/logo.jpg",
  textColor: "#ffffff"
};

const transformPayload = (payload) => {
  const transformKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(item => transformKeys(item));
    }
    else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        const camelCaseKey = key.replace(/_([a-z])/g, g => g[1].toUpperCase());
        acc[camelCaseKey] = transformKeys(obj[key]);
        return acc;
      }, {});
    }
    return obj;
  };

  return transformKeys(payload);
};

const linksReducer = (state = initialState, action) => {
  switch (action.type) {
    case LINKS_FETCH_SUCCESS:
      return {
        ...state,
        ...transformPayload(action.payload),
        error: null,
      };
    case LINKS_FETCH_FAILURE:
      return {
        ...initialState,
        error: action.payload.error,
      };
    case LINKS_UPDATE_SUCCESS:
      return {
        ...state,
        ...transformPayload(action.payload),
        error: null,
      };
    case LINKS_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default linksReducer;

export const getLinks = state => state.linksReducer;
