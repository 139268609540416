import {
  CONFIG_FETCH_SUCCESS,
  CONFIG_FETCH_FAILURE,
  CONFIG_UPDATE_SUCCESS,
  CONFIG_UPDATE_FAILURE,
} from '../actions/configActionTypes';

const initialState = {
  plans: [],
  user: {},
  error: null,
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIG_FETCH_SUCCESS:
      return {
        ...state,
        plans: action.payload.user?.plans,
        user: action.payload.user,
        error: null,
      };
    case CONFIG_FETCH_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case CONFIG_UPDATE_SUCCESS:
      return {
        ...state,
        plans: action.payload.user?.plans,
        user: action.payload.user,
        error: null,
      };
    case CONFIG_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

export default configReducer;

